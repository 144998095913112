import React from 'react';
import StockDataField from './StockDataField';
import FieldPSImage from '../Components/Fields/FieldPSImage';

export default class StockDataFieldPSImage extends StockDataField {
  getMarkup() {
    return (
      <FieldPSImage value={this.getValue()} />
    );
  }
}

