import React, {useState, useEffect, Fragment, useContext, useMemo} from 'react';
import {Transition, Popover} from '@headlessui/react';
import {isEqual, orderBy} from 'lodash';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import ScannerContext from '../../../Context/ScannerContext';

const searchRankPriority = {
  EXACTMATCH: 100000,
  PARTIALMATCH: 10000,
  INCLUDES: 1000,
  MISSING: 0,
};

const MultiSelectWithSearch = () => {
  const {group, multiScreen, handleUpdateMultiSelect} = useContext(ScannerContext);
  if (!group?.searchValue?.filters.length) return null;
  const [filter, setGroupFilters] = useState(null);
  const [label, setLabel] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [allValues, setAllValues] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (group?.searchValue?.filters?.length) {
      const filtFind = group?.searchValue?.filters?.find(
        (filt) => filt && filt?.filterable?.type === 'multiselect' && filt?.type === 'string',
      );
      setGroupFilters(filtFind);
      setLabel(filtFind?.title);
    }
  }, [group?.searchValue?.filters]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const clearAll = () => {
    handleUpdateMultiSelect(null, filter?.key, null, true);
    setSelected([]);
  };

  const hasNumbers = (t) => {
    const regex = /\d/g;
    return regex.test(t);
  };

  useEffect(() => {
    const values =
      filter && filter?.filterable?.options?.length
        ? filter?.filterable?.options.slice().sort((a, b) => {
            const aKey = a?.key ?? a;
            const bKey = b?.key ?? b;
            return aKey.localeCompare(bKey);
          })
        : [];
    if (isEqual(values, allValues)) return;
    setAllValues(values);
  }, [filter, filter?.filterable?.options]);

  useEffect(() => {
    if (allValues?.length) {
      if (!searchValue) {
        setCheckboxValues(allValues);
        return;
      }
      const filtered = allValues?.filter((item) => {
        const itemValue = item?.key ?? item;
        return itemValue.toLowerCase().includes(searchValue.toLowerCase());
      });

      if (filtered?.length) {
        const formattedForOrderBy = filtered.map((item) => {
          const itemValue = item?.key ?? item;
          if (searchValue?.length) {
            const tickerSymbol = hasNumbers(itemValue) && itemValue ? itemValue.split(/[0-9]/)[0] : itemValue;
            const splitValue = itemValue.split(searchValue)?.filter((t) => t?.length);
            let remainingSymbol = '';
            if (splitValue?.length) {
              remainingSymbol = splitValue[splitValue?.length - 1];
              if (remainingSymbol?.length > 1) {
                remainingSymbol = remainingSymbol?.slice(0, 1);
              }
            }
            if (tickerSymbol?.toLowerCase() === searchValue?.toLowerCase()) {
              return {
                rank: searchRankPriority.EXACTMATCH,
                ticker: tickerSymbol,
                remaining: remainingSymbol,
              };
            }
            if (tickerSymbol?.slice(0, searchValue?.length)?.toLowerCase() === searchValue?.toLowerCase()) {
              return {
                rank: searchRankPriority.PARTIALMATCH,
                ticker: tickerSymbol,
                remaining: remainingSymbol,
              };
            }
            return {
              rank: searchRankPriority.INCLUDES,
              ticker: tickerSymbol,
              remaining: remainingSymbol,
            };
          }

          return {
            rank: searchRankPriority.MISSING,
            ticker: itemValue,
            remaining: '',
          };
        });
        const sorted = orderBy(formattedForOrderBy, ['rank', 'remaining', 'ticker'], ['desc', 'asc', 'asc']);
        setCheckboxValues(sorted.map((item) => item.ticker));
        return;
      }

      setCheckboxValues([]);
    }
  }, [searchValue, allValues]);

  const memoizedCheckboxValues = useMemo(() => checkboxValues, [checkboxValues]);
  const memoizedFilter = useMemo(() => filter, [filter?.value?.length]);

  const updateFilters = (e, filt, field) => {
    handleUpdateMultiSelect(e.target.checked, filt?.key, field);
    setSelected((prev) => (e.target.checked ? [...prev, field] : prev.filter((item) => item !== field)));
  };

  return (
    <div className="flex flex-col grow w-1/6 gap-2">
      <div className="flex items-center justify-start gap-2">
        <Popover as="div" className="relative inline-block text-left">
          <div>
            <Popover.Button
              style={{background: 'rgba(148, 196, 242, 0.1)'}}
              className={`${
                multiScreen ? '' : 'px-4 py-2 '
              } whitespace-nowrap filter-label items-center px-2 py-1 inline-flex justify-center w-full rounded-[50px] border-none  text-[#70769B] border-gray-300 shadow-sm bg-white text-md font-medium  hover:bg-gray-50 focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-transparent`}
            >
              {label}
              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="w-auto origin-top-left absolute left-0 mt-2 rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none multiselect-search-wrap rounded-t-[10px] ">
              <div className="multi-select-search border-0">
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder="Search for Symbol"
                  className="border-0 border-b border-gray-300 block  p-1.5 text-gray-900 placeholder-gray-500 focus:ring-0 rounded-t-[10px] search-input"
                />
              </div>
              <div className="divide-y divide-gray-200 list-dropdown">
                <fieldset className="px-4 py-2 gap-0.5 flex flex-col h-56 overflow-y-scroll">
                  {memoizedCheckboxValues?.map((field) => (
                    <div className="relative flex items-center py-0.5" key={field?.key ?? field}>
                      <div className="min-w-0 flex-1 text-md">
                        <label
                          htmlFor={field?.key ?? field}
                          className="font-medium text-gray-700 checkbox-label cursor-pointer"
                        >
                          {field}
                        </label>
                      </div>
                      <div className="ml-3 flex items-center h-5 cursor-pointer">
                        <input
                          onChange={(e) => updateFilters(e, filter, field)}
                          checked={selected.includes(field)}
                          id={field?.key ?? field}
                          aria-describedby={field?.key ?? field}
                          name={field?.key ?? field}
                          type="checkbox"
                          className="focus:ring-transparent h-4 w-4 text-sky-500 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  ))}
                </fieldset>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        {filter?.value?.length ? (
          <button type="button" className="btn focus:ring-transparent clear-all" onClick={clearAll}>
            Clear All
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(MultiSelectWithSearch, (prev, next) => {
  const optionsSame =
    isEqual(prev?.filter?.filterable?.options, next?.filter?.filterable?.options) &&
    isEqual(prev?.filter?.value, next?.filter?.value);
  return optionsSame;
});

// export default MultiSelectWithSearch;
