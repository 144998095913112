import React,{useContext} from 'react'; 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import ScannerContext from '../../Context/ScannerContext';

const FieldPopOut = ({
  fieldName,
}) => {
  const {optionsColumns} = useContext(ScannerContext);

  const fieldOption = optionsColumns[fieldName];
  const columnGroup = fieldOption?.columns?.length ? fieldOption.columns : [];

  // TODO displayOptionsPopout !!!!!!!!!!!!!!!!!!!!!!!!!
  const displayOptionsPopout = true;
  // const displayOptionsPopout = columnGroup?.length
  //  ? Object.keys(rowValue ?? {}).some((r) => columnGroup?.includes(r)) && openOptions
  //  : false;

  if (!displayOptionsPopout) {
    return null
  }
  return (
        <button
          type="button"
          label="open options"
          onClick={() => {
            // TODO maybe shoot the symbol and group data for the popot to get the data? maybe not
            // openOptions(rowValue, key);
          }}
          className={`open-options ${fieldOption?.overrideOpenIcon ? 'override-open-icon' : ''}`}
        >
          {fieldOption?.overrideOpenIcon && fieldOption?.overrideOpenIcon?.icon ? (
            <img
              src={fieldOption?.overrideOpenIcon?.icon}
              alt="open-options"
              style={fieldOption?.overrideOpenIcon?.style ?? {}}
              className="open-options-icon"
            />
          ) : (
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            )}
        </button>
  );
}

export default FieldPopOut
