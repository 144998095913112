import React from 'react';
import StockDataField from './StockDataField';
import FieldBasic from '../Components/Fields/FieldBasic';

const NumAbbr = require('number-abbreviate');

export default class StockDataFieldVolume extends StockDataField {
  getMarkup() {
    return (<FieldBasic value={`${NumAbbr(Number(this.getValue()), 2)}`.toUpperCase()} style={this.getStyle()} />);
  }
}


