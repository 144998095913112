import React from 'react'; 

const FieldIcon = ({
  value,
  style,
  iconType
}) => {
  if (!value) {
    return null;
  }

  // TODO backend is not consistent
  // 1 -> iconType iconTypeOverride
  // -1 -> iconType overrideValue
  const iconClass = iconType || style.overrideValue;

  return (
    <span className="text-sky-400" style={style}>
      <i className={iconClass} />
    </span>
   );
}

export default FieldIcon
