import React from 'react';
import StockDataField from './StockDataField';
import FieldSamSpeaks from '../Components/Fields/FieldSamSpeaks';

export default class StockDataFieldSamSpeaks extends StockDataField {
  getMarkup() {
    return (
      <FieldSamSpeaks symbol={this.parentEntry.getSymbol()} style={this.getStyle()} />
    );
  }
}

