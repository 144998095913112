import React, {useContext} from 'react'; 
import {ReactComponent as SamSpeaksIcon} from '../../Icons/SAM-speaks.svg';
import AppContext from '../../Context/AppContext';
import ScannerContext from '../../Context/ScannerContext';

const FieldSamSpeaks = ({
  symbol,
  style
}) => {
  const {useAI} = useContext(ScannerContext);

  // openAIModal is defined in Scanner.js and used only here
  // TODO define here and remove from context
  const {openAIModal} = useContext(AppContext);

  if (!useAI || !symbol) {
    return null;
  }

  return (
    <button
      onClick={() => {
        openAIModal(symbol);
      }}
      type="button"
    >
      <SamSpeaksIcon  alt="SAM Speaks" style={style} className="ai-item" />
    </button>
  );
}

export default FieldSamSpeaks
