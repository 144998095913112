import React from 'react'; 
import PSUIcon from '../../Icons/PSU-icon.png';

const FieldPSImage = ({
  value
}) => {
  if (!value) {
    return null;
  }

  return (
    <span className="ps-icon-wrap">
      <img src={PSUIcon} className="ps-icon" alt="ps-icon" />
    </span>
   );
}

export default FieldPSImage
