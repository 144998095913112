import React from 'react';
import StockDataField from './StockDataField';
import FieldIcon from '../Components/Fields/FieldIcon';

export default class StockDataFieldIcon extends StockDataField {
  getMarkup() {
    return (
      <FieldIcon
        value={this.getValue()}
        style={this.getStyle()}
        // TODO see if it is really worth to take it from dataType?
        iconType="fa-solid fa-check text-white"
      />
    );
  }
}

