import React from 'react'; 
import FieldAPlus from './FieldAPlus';
import FieldNumber from './FieldNumber';
import FieldIcon from './FieldIcon';

const FieldNumberStringArrow = ({
  stockDataField,
}) => {
  // TODO backend should break this down much easier and simplify here

  // If no data for this field leave empty
  if (!stockDataField.getData()) {
    return '';
  }

  const columnA = stockDataField.getColumn('A'); // Number
  const columnB = stockDataField.getColumn('B'); // String
  const columnC = stockDataField.getColumn('C'); // Arrow
  return (
    <span style={{ display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center' }} > 
      { columnA && (<FieldNumber value={stockDataField.getColumnValue('A')} />)}
      { columnB && (<FieldAPlus value={stockDataField.getColumnValue('B')} />)}
      { columnC && (<FieldIcon 
        value={stockDataField.getColumnValue('C')}
        style={stockDataField.getColumnStyle('C')}
        iconType={stockDataField.getColumnStyle('C')?.iconTypeOverride} />)}
    </span>
   );
}

export default FieldNumberStringArrow
