import StockDataField from './StockDataField';
import StockDataFieldSymbol from './StockDataFieldSymbol';
import StockDataFieldPrice from './StockDataFieldPrice';
import StockDataFieldVolume from './StockDataFieldVolume';
import StockDataFieldPercent from './StockDataFieldPercent';
import StockDataFieldNumber from './StockDataFieldNumber';
import StockDataFieldIcon from './StockDataFieldIcon';
import StockDataFieldNumberStringArrow from './StockDataFieldNumberStringArrow';
import StockDataFieldPopOut from './StockDataFieldPopOut';
import StockDataFieldSamSpeaks from './StockDataFieldSamSpeaks';
import StockDataFieldPSImage from './StockDataFieldPSImage';

class StockDataFieldFactory {
  static create(key, data, entry) {
    // Eventually this could be assigned according to a type defined by a config file or server extracted data
    switch (key) {

      case 'Symbol': // Symbol
        return new StockDataFieldSymbol(key, data, entry);

      case 'Last': 
        return new StockDataFieldPrice(key, data, entry);

      // Former type: number-short
      case 'Volume': // Vol.
        return new StockDataFieldVolume(key, data, entry);

      // Former type: percentage
      case 'PctChange': // Change (%)
        return new StockDataFieldPercent(key, data, entry);

      // Former Type: number
      case 'NetChange': // Change (Net)
      case 'MS_PC': // S-Up
      case 'MS_MC': // S-Down
      case 'Vol_Buzz': // Vol Buzz
        return new StockDataFieldNumber(key, data, entry);

      // Former Type: Icon
      case 'InsideDay':
        return new StockDataFieldIcon(key, data, entry);

      // joined-column TODO see new backend dataType
      // TODO on backend breakdown the joined-columns
      // For now support of this
      case 'DPL_S_Week+MS_Week+MSDir_Week':// W
      case 'DPL_S_Day+MS_Day+MSDir_Day': // D
      case 'DPL_S_195+MS_195+MSDir_195': // 195m 
      case 'DPL_S_130+MS_130+MSDir_130': // 130m 
      case 'DPL_S_78+MS_78+MSDir_78': // 78m
      case 'DPL_S_60+MS_60+MSDir_60': // 60m
      case 'DPL_S_30+MS_30+MSDir_30': // 30m
      case 'DPL_S_15+MS_15+MSDir_15': // 15m
      case 'DPL_S_10+MS_10+MSDir_10': // 10m
      case 'DPL_S_5+MS_5+MSDir_5': // 5m
        return new StockDataFieldNumberStringArrow(key, data, entry);

      case 'DPO_Popout':
        return new StockDataFieldPopOut(key, data, entry);

      case 'Sam Speaks': // SAMSpeaks
        return new StockDataFieldSamSpeaks(key, data, entry);

      case 'ProfitSurge': // Profit Surge
        return new StockDataFieldPSImage(key, data, entry);

      // TODO check if need to support?
      // Fallback to base class for now
      case 'ListedExchange':
      case 'InstrumentType':
      case 'Close':
      case 'DPL_S_Day':
      case 'MSDir_Day':
      case 'MDTy':
      case 'MDAStk':
      case 'MDAEx':
      case 'MDCStk':
      case 'MDCEx':
      case 'MDMStk':
      case 'MDMEx':
      case 'DPL_S_10':
      case 'MS_10':
      case 'MSDir_10':
      default:
        return new StockDataField(key, data, entry);
    }
  }
}

export default StockDataFieldFactory;

