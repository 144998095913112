import React, {useState, useContext, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart as faHeartRegular} from '@fortawesome/free-regular-svg-icons';
import {faHeart} from '@fortawesome/free-solid-svg-icons';
import AppContext from '../../Context/AppContext';
import ScannerContext from '../../Context/ScannerContext';

// TODO watchlist works, but does not show in personal watchlist tab
// optimize handleClick method

const StockSymbol = ({
  stockDataField
}) => {
  const {group} = useContext(ScannerContext);
  // TODO active watchlist import and remove from all
  const {getSymbolData, activeWatchlistSymbols, handleAddToWatchlist} = useContext(AppContext);
  const [addedToWatchlist, setIsAddedToWatchlist] = useState(false);

  const isUserAllowedWatchlist = group?.allowedWatchlist;
  const isUserAllowedChart = group?.allowedChart;

  const fieldName = stockDataField.getName() || null;
  const style = stockDataField.getStyle();
  const value = stockDataField.getValue() || null;
  const stockGroup = stockDataField.getGroup();

  const baseClass =
    Object.keys(style ?? {})?.length && Object.keys(style ?? {}).includes('backgroundColor')
      ? `color-item ${Object.keys(style ?? {}).length ? 'inline-styles' : ''}`
      : `no-color-item ${Object.keys(style ?? {}).length ? 'inline-styles' : ''}`;

  const handleClickSymbol = () => {
    if (value && fieldName === 'Symbol' && isUserAllowedChart) {
      // if (displayChartDisabledMessage) {
      //   // eslint-disable-next-line no-alert
      //   alert(
      //     'Charts are offline to help increase SAM speed. We will have these up and running soon. Thanks for your patience!',
      //   );
      //   return;
      // }
      getSymbolData(value, stockGroup);
    }
  };

  const isAddedToWatchlist = ({stockSymbol}) => {
    if (!isUserAllowedWatchlist || !activeWatchlistSymbols?.length || !Array.isArray(activeWatchlistSymbols)) return false;
    return activeWatchlistSymbols?.includes(stockSymbol);
  };

  // Watchlist
  useEffect(() => {
    const isAdded = isAddedToWatchlist({
      stockSymbol: stockDataField.getValue(),
    });
    if (addedToWatchlist !== isAdded) {
      setIsAddedToWatchlist(isAdded);
    }
  }, [activeWatchlistSymbols, stockDataField]);

  if (isUserAllowedWatchlist) {
    return (
      <div
        className="flex items-center gap-1.5 justify-center symbol-watchist-wrap px-1.5"
        style={{zIndex: 99999999}}
      >
        <button
          onClick={() => {
            if (!isUserAllowedChart) return;
            handleClickSymbol();
          }}
          type="button"
        >
          <span
            style={style}
            className={`${baseClass} symbol-item ${
              isUserAllowedChart ? 'cursor-pointer' : ' cursor-default symbol-item-no-link'
            } `}
          >
            {value}
          </span>
        </button>
        {isUserAllowedWatchlist ? (
          <button
            onClick={() => {
              if (!isUserAllowedWatchlist) return;
              handleAddToWatchlist(stockGroup, value, !addedToWatchlist);
            }}
            type="button"
          >
            <FontAwesomeIcon
              className={`${addedToWatchlist ? 'added' : ''} watchlist-item`}
              icon={addedToWatchlist ? faHeart : faHeartRegular}
            />
        </button>
        ) : null}
      </div>
    );
  } 
  return (
    <button
      type="button"
      style={style}
       onClick={() => {
         if (!isUserAllowedChart) return;
         handleClickSymbol();
      }}
      className={`${baseClass} symbol-item ${
        isUserAllowedChart ? 'cursor-pointer' : ' cursor-default symbol-item-no-link'
      } `}
      >
        {value}
    </button>
  );

}

export default StockSymbol;

