import React from 'react';
import StockDataField from './StockDataField';
import FieldNumberStringArrow from '../Components/Fields/FieldNumberStringArrow';

export default class StockDataFieldNumberStringArrow extends StockDataField {
  getColumnSortingValue(columnName, baseWeight = 100000) {
    const columnValue = this.getColumnValue(columnName)
    let sortingValue = baseWeight;

    // If null or undefined no value
    if (columnValue == null) { 
      return 0;
    }

    // If it is a number at its value to the sorting
    if (typeof columnValue === 'number' && !Number.isNaN(columnValue)) {
      sortingValue += columnValue;
    } else if (typeof columnValue === 'boolean') {
      // If it is a boolean add 1 if true substract 1 if false
      sortingValue += columnValue ? 1 : -1;
    }

    return sortingValue;
  }

  // the joined-column strategy needs to be reviewed until then this complexity seems to be needed
  // This is a port of the original strategy
  getSortingValue(key, defaultValue = null) {
    const columnA = this.getColumn('A'); // Number
    const columnB = this.getColumn('B'); // String
    const columnC = this.getColumn('C'); // Arrow
    
    let sortingValue = 0;

    if (columnA) {
      sortingValue += this.getColumnSortingValue('A', 100000);
    }
    if (columnB) {
      sortingValue += this.getColumnSortingValue('B', 10000);
    }

    if (columnC) {
      sortingValue += this.getColumnSortingValue('C', 1000);
    }

    return sortingValue;
  }

  getMarkup() {
    return (<FieldNumberStringArrow stockDataField={this} />);
  }
}


