import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {isEqual, differenceWith, orderBy} from 'lodash';
import {useOutletContext} from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ScannerTable from './ScannerTable';
import Header from '../Header/Header';
import 'react-datepicker/dist/react-datepicker.css';
import ScannerContext from '../../Context/ScannerContext';
import { updateFilters } from '../../Helpers/formatData';
import StockDataStore from '../../externalStoreStocksData';

dayjs.extend(utc);
dayjs.extend(timezone);

const Scanner = ({
  group,
  multiScreen,
  optionsColumns,
  optionsAllowed,
  lockedColumns,
  groupName,
  displayChartDisabledMessage,
  currentDate,
  useAI,
}) => {
  const [, dispatch] = useOutletContext();
  const [groupState, setGroupState] = useState(group);
  const [orderedData, setOrderedData] = useState([]);
  const [inView, setInView] = useState([]);
  const [orderByState, setOrderBy] = useState(group?.orderBy);
  const [orderState, setOrder] = useState(group?.order);
  const [rowsPerPageState, setRowsPerPage] = useState(group?.rowsPerPage ?? 100);
  const [pageState, setPage] = useState(group?.page ?? 1);
  const [selectedFilters, setSelectedFilters] = useState([]);

  // Update selectedFilters state
  // add/remove the filter column and value
  const handleUpdateMultiSelect = useCallback(
    (checked, column, value, clearAll = false) => {
      setSelectedFilters((prevFilters) => {
        if (clearAll) {
          // Clear all filters if clearAll is true
          return [];
        }

        // Find if the column already exists
        const existingFilter = prevFilters.find((filter) => filter.column === column);
        if (checked) {
          if (existingFilter) {
            // If column exists, add the value to its list (if not already there)
            return prevFilters.map((filter) =>
              filter.column === column
                ? { ...filter, values: [...new Set([...filter.values, value])] }
                : filter
            );
          }
          // If column doesn't exist, add a new filter object
          return [...prevFilters, { column, values: [value] }];
        }

        if (existingFilter) {
          // If column exists, remove the value from its list
          const updatedValues = existingFilter.values.filter((val) => val !== value);

          if (updatedValues.length > 0) {
            // Keep the column if it still has values
            return prevFilters.map((filter) =>
              filter.column === column ? { ...filter, values: updatedValues } : filter
            );
          }
          // Remove the column entirely if no values remain
          return prevFilters.filter((filter) => filter.column !== column);
        }

        // Return the unchanged filters if nothing needs to be updated
        return prevFilters;
      });
    },
    []
  );

  // TODO check this if it still works with externalStore data
  const hasFilters = useCallback(() => {
    const filters = group?.searchValue?.filters;
    const withoutSearch = filters?.filter(
      (filt) =>
        filt?.filterable?.type !== 'search' && filt?.filterable?.type !== 'multiselect' && filt?.type !== 'string',
    );
    const boolAndAdditionalFeatures =
      group?.searchValue?.boolFilters?.filter((field) => field?.fields?.length).length ||
      group?.searchValue?.additionalFilters?.length ||
      group?.searchValue?.bearBullFilters?.length ||
      group?.showBearBullFilter;
    const multiScreenRange =
      multiScreen && withoutSearch?.filter((filter) => filter && filter?.filterable?.type === 'range')?.length;
    return withoutSearch?.length || boolAndAdditionalFeatures || multiScreenRange;
  }, []);

  const handleChangePage = useCallback((event, newPage) => {
    if (!group?.group) return;
    dispatch({type: 'SET_PAGE', payload: {group, page: newPage}});
  }, []);

  // Create a Set of keys for columns which will highligh on change
  const highlightKeys = groupState?.dataTypes.reduce((set, dt) => {
    if (dt.type === 'number' && !dt?.hideColumn) {
      set.add(dt.key);
    }
    return set;
  }, new Set());

  let displayColumns = [];
  if (group?.dataTypes?.length) {
    displayColumns = group?.dataTypes.filter((dt) => !dt?.hideColumn);
  }
  // This is for price formatting
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  // This is provided for the ScannerContext
  // TODO optimization
  // for example, group seems like handling too much data
  const contextValue = useMemo(
    () => ({
      group: groupState,
      highlightKeys,
      displayColumns,
      formatPrice,
      multiScreen,
      optionsColumns,
      useAI,
      optionsAllowed,
      lockedColumns,
      groupName,
      displayChartDisabledMessage,
      allowedChart: groupState?.allowedChart ?? group?.allowedChart,
      handleUpdateMultiSelect,
      hasFilters,
      handleChangePage,
    }),
    [
      groupState,
      multiScreen,
      optionsColumns,
      useAI,
      optionsAllowed,
      lockedColumns,
      groupName,
      displayChartDisabledMessage,
    ],
  );

  // Filter data (array of stockDataEntry object) 
  const filterData = useCallback((data) => {
    if (!selectedFilters.length) {
      return data; 
    }

    return data.filter((stockDataEntry) => {
      // TODO remove this eslint error
      console.log('test');
      // Check if the item matches all the filters
      return selectedFilters.every(({ column, values }) => {
        const columnValue = stockDataEntry.getValue(column);
        // Check if the value is in the filter's values
        return values.includes(columnValue); 
      });
    });
  }, [selectedFilters]); // Dependencies ensure recomputation when these values change

  // Keep orderredData up-to-date with with externalStore data from stock group
  useEffect(() => {
    const unsubscribe = StockDataStore.getInstance(groupName).subscribeToSortedData((sortedStockData, currentSortField) => {
      // This [..sortedStockData] is needed instead of sortedStockData
      // This is due because the reference of objects do not change only the data inside
      // this has some performance cost but negligible for now
      setOrderedData(filterData([...sortedStockData]));
    });

    // Perform initial sorting on mount
    // The sorting column and direction is saved, and data is updated in the right order instead of resorted completely at every update 
    StockDataStore.getInstance(groupName).sort(orderByState, orderState);

    return () => {
      unsubscribe(); // Clean up on unmount ex. when groupName or orderByState changes
    };
  }, [groupName, orderByState, orderState, selectedFilters]);


  // Filter with pagination
  // orderedData is deep array of objects so changes are not reliably
  // detected if objects are the same reference with new preoperties values 
  // do it is not trigger here
  useEffect(() => {
    if (!orderedData?.length) {
      return;
    }

    const newInView = orderedData.slice(
      pageState * rowsPerPageState,
      pageState * rowsPerPageState + rowsPerPageState
    );
    setInView([...newInView]);

  }, [orderedData, rowsPerPageState, pageState]);

  // This is the click handler when user click on column header for sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderByState === property && orderState === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    // unneeded
    // const filterType = groupState?.dataTypes?.find((type) => type.key === property);
    if (property === 'Symbol') {
      setOrderBy(property);
      setOrder(newOrder);
    } else {
      const newOrderElse = orderByState === property && orderState !== 'asc' ? 'asc' : 'desc';
      setOrderBy(property);
      setOrder(newOrderElse);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
  };

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  // Avoid a layout jump when reaching the last page with empty tableData.
  const emptyRows = useMemo(
    () => (pageState > 0 ? Math.max(0, (1 + pageState) * rowsPerPageState - orderedData?.length) : 0),
    [pageState, rowsPerPageState, orderedData],
  );

  const count = orderedData.length;
  return (
    <ScannerContext.Provider value={contextValue}>
      <div className="scanner-wrap">
        <Header currentDate={currentDate} />
        {group ? (
          <div className="scanner">
            <ScannerTable
              inView={inView}
              emptyRows={emptyRows}
              count={count}
              dataTypes={groupState?.dataTypes}
              allowedChart={groupState?.allowedChart}
              groupName={groupState?.group}
              orderState={orderState}
              orderByState={orderByState}
              handleRequestSort={handleRequestSort}
              rowsPerPageState={rowsPerPageState}
              pageState={pageState}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              changePage={changePage}
            />
          </div>
        ) : (
          <div className="scanner"> Loading Scanner... </div>
        )}
      </div>
    </ScannerContext.Provider>
  );
};
/*
export default React.memo(Scanner, (prev, next) => {
  const dataSame = isEqual(prev?.group?.filteredData, next?.group?.filteredData);
  const optionsColumnsSame = isEqual(prev?.optionsColumns, next?.optionsColumns);
  const optionsAllowedSame = isEqual(prev?.optionsAllowed, next?.optionsAllowed);
  const useAISame = prev?.useAI === next?.useAI;
  const lockedColumnsSame = isEqual(prev?.lockedColumns, next?.lockedColumns);
  const groupNameSame = prev?.groupName === next?.groupName;
  const displayChartDisabledMessageSame = prev?.displayChartDisabledMessage === next?.displayChartDisabledMessage;
  const currentDateSame = prev?.currentDate === next?.currentDate;
  return (
    dataSame &&
    optionsColumnsSame &&
    optionsAllowedSame &&
    lockedColumnsSame &&
    groupNameSame &&
    displayChartDisabledMessageSame &&
    currentDateSame &&
    useAISame
  );
});
*/
export default Scanner;
