import React from 'react';
import StockDataField from './StockDataField';
import FieldPopOut from '../Components/Fields/FieldPopOut';

export default class StockDataFieldPopOut extends StockDataField {
  getMarkup() {
    return (<FieldPopOut fieldName={this.getName()}  />);
  }
}


