import React from 'react'; 

const FieldAPlus = ({
  value,
}) => {
  if (!value) {
    return null;
  }

  // TODO fix backend This is what it returns when A+ is red
  // MS_5: {…}
    // style: {…}
      // backgroundColor: "rgba(181, 14, 26, 0.5)"
      // borderRadius: "4px"
      // fontSize: "12px"
      // padding: "5px 6px"
    // value: "A+"
  const aColorClass = value === 'A-' ? 'card-red' : '';

  return (
    <span className={`color-item aplus-card ${aColorClass}`}>A+</span>
   );
}

export default FieldAPlus

