import React from 'react'; 

const FieldBasic = ({
  value,
  style
}) => {
  const baseClass = 'no-color-item';

  if (!value) {
    return null;
  }

  return (
    <span className={baseClass} style={style}>
    {value}
    </span>
   );
}

export default FieldBasic
