import React from 'react'; 

const FieldNumber = ({
  value,
  style
}) => {
  const baseClass = 'no-color-item';

  // Note this format is quite CPU costly? for what it is if needed make a custom simple fit for our needs
  const formatNumber = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  });

  if (!value || Number.isNaN(Number(value))) {
    return null;
  }

  return (
    <span className={baseClass} style={style}>
    {formatNumber.format(Number(value))}
    </span>
   );
}

export default FieldNumber
