import React, {useContext} from 'react';
import StockDataField from './StockDataField';
import FieldBasic from '../Components/Fields/FieldBasic';
import ScannerContext from '../Context/ScannerContext';

export default class StockDataFieldPrice extends StockDataField {
  getMarkup() {
    // Format price config
    const {formatPrice} = useContext(ScannerContext);

    return (<FieldBasic value={formatPrice.format(Number(this.getValue()))} style={this.getStyle()} />);
  }
}

