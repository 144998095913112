import React from 'react';

class StockDataField {
  constructor(key, data, entry) {
    this.name = key;
    this.data = data;
    // StockDataField parent object
    this.parentEntry = entry;
  }

  getGroup() {
    return this.parentEntry.getGroup();
  }

  getName() {
    return this.name;
  }

  getData() {
    return this.data;
  }

  getKey() {
    const key = `${this.parentEntry.getEntryId()}-${this.getName()}`;
    return key;
  }

  // This is used the value that will be used for sorting purposes
  // Extended for if needed for example for non-numeric field
  getSortingValue(defaultValue = null) {
    return this.getValue(defaultValue)
  }

  getValue(defaultValue = null) {
    return this.data?.value ?? defaultValue;
  }

  getStyle(defaultStyle = {}) {
    return this.data?.style ?? defaultStyle;
  }

  // These get column should be unneeded when backend is fixed of the "joined-column" bonanza
  getColumn(columnName) {
    const columnIndex = `column${columnName}`;
    if (!this.data) {
      return null;
    }
    return this.data[columnIndex];
  }
  
  getColumnValue(columnName) {
    const columnDataName = this.getColumn(columnName);
    return this.parentEntry.getValue(columnDataName);
  }

  getColumnStyle(columnName) {
    const columnDataName = this.getColumn(columnName);
    return this.parentEntry.getStyle(columnDataName);
  }

  // A class should be created for each types of field 
  // and extends this method if markup differs from this base class
  getMarkup() {
    return <span>{this.getValue()}</span>;
  }

}
export default StockDataField;
