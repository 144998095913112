import React from 'react';
import StockDataField from './StockDataField';
import FieldNumber from '../Components/Fields/FieldNumber';

export default class StockDataFieldNumber extends StockDataField {
  getMarkup() {
    return (<FieldNumber value={this.getValue()} style={this.getStyle()} />);
  }
}

